import(/* webpackMode: "eager", webpackExports: ["AuthKitProvider"] */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/authkit-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["MinMaxButton"] */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/min-max-button.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AppMenu.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/FrontChat.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/Link.js");
